.secao {

  &.institucional {
    padding-bottom: 90PX;
  }
}

// Banner do topo
.institucional-banner {
  float: left;
  width: 100%;
  height: 200px;
  background-color: #DDD;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  // TABLET
  @include mq(m) {
    height: 150px;
  }

  // MOBILE
  @include mq(s) {
    height: 100px;
  }
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {

  // DESKTOP
  @include mq(l) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
