// Header principal
.header-full {
  position: fixed;
  z-index: $z_index_header;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  padding: 10px 0px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease;

  // MOBILE
  @include mq(s) {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
  }

  &.active {
    // MOBILE
    @include mq(s) {
      transform: translate(0, -30px);
    }
  }
}

// Conteúdo (Container)
.header-full .container {
  position: static;
}
.header-content {
  position: relative;
  padding: 0 10px;

  // MOBILE
  @include mq(s) {
    position: static;
  }
}

// Logo
.header-full .header-logo {
  display: block;
  float: left;
  max-width: 300px;

  // TABLET
  @include mq(m) {
    max-width: 200px;
    margin-top: 10px;
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 150px;
  }

  // Logo branco
  &.white-logo {
    display: none;
  }
}

// Direita
.header-full .header-direita {
  float: right;

  // MOBILE
  @include mq(s) {
    height: 0px;
  }
}

// Telefones
.header-telefones {
  float: right;
  text-align: right;
  position: absolute;
  top: -10px;
  right: 10px;
  background: $cor_primaria;
  padding: 5px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  // MOBILE
  @include mq(s) {
    top: 0;
    left: 0;
    right: 0;
    padding: 3px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    background: #004d69;
    border-radius: 0;
  }

  // Item
  a {
    display: inline-block;
    margin-left: 20px;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 14px;
      margin: 0 5px;
    }

    i {
      margin-right: 5px;
      color: #fff;
    }
  }
}

// Idiomas
.header-langs {
  float: left;
  color: $cor_fonte_clara;

  // MOBILE
  @include mq(s) {
    position: absolute;
    top: 47px;
    right: 20px;
  }

  a {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: $cor_fonte_escura;
    transition: 0.2s;

    &:hover {
      color: $cor_primaria;
    }
  }
}

// ---------------------------- HOME ---------------------------- //
body.home-full {
  padding-top: 0px;
}

// Header
.home-full .header-full:not(.active) {
  background: none;
  background-image: linear-gradient(to bottom, rgba(#000, 0.7), rgba(#000, 0));
  box-shadow: none;
  padding-top: 20px;
  padding-bottom: 20px;

  // MOBILE
  @include mq(s) {
    padding-top: 45px;
  }
}

// Logo
.home-full .header-full:not(.active) .header-logo {
  // Logo COLORIDO
  &.colored-logo {
    display: none;
  }

  // Logo BRANCO
  &.white-logo {
    display: block;

    // MOBILE
    @include mq(s) {
      display: inline-block;
    }
  }
}

// Idiomas
.home-full .header-full:not(.active) .header-langs {
  color: #fff;

  a {
    color: #fff;

    &:hover {
      color: $cor_primaria;
    }
  }
}
.home-full .header-full:not(.active) .header-telefones a {
  color: #fff;
}

// Telefones
.home-full .header-full:not(.active) .header-telefones {
  top: -20px;

  // MOBILE
  @include mq(s) {
    top: 0;
    background: rgba(#fff, 0.1);
  }
}
.home-full .header-full:not(.active) .header-telefones a {
  color: #fff;
}

// Botão
.home-full .header-full:not(.active) .outline {
  border: 1px solid #fff;
  color: #fff;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background: #fff;
    color: $cor_primaria;
  }
}

// Menu lateral
.home-full .header-full:not(.active) .lateral .fdx-menu-1-level > li > a {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:before {
    background: #fff;
  }
}

// Menu lateral
.home-full .header-full:not(.active) .fdx-menu.lateral {
  margin-top: 40px;
}

// Botão menu mobile
.home-full .header-full:not(.active) .menu-btn-mobile {
  border-color: #fff;

  &::before,
  &::after {
    background: #fff;
  }
}

// Slide
.home-full .slide-item .slide-image {
  height: 100vh;
  min-height: 600px;

  // TABLET
  @include mq(m) {
    height: 90vh;
  }

  // MOBILE
  @include mq(s) {
    height: 80vh;
    min-height: 400px;
  }
}
.home-full .slide-item .slide-content {
  padding-top: 100px;

  // MOBILE
  @include mq(s) {
    padding-top: 50px;
  }
}
