.chamada {
  
  .texto {
    color: #FFF;
    font-size: 28px;
    font-weight: 600;

    // MOBILE
    @include mq(s) {
      font-size: 18px;
    }
  }

}
