// Seção
.home-sobre {
}

// Esquerda
.home-sobre-left {
  padding-right: 10px;

  // MOBILE
  @include mq(s) {
    padding-right: 0px;
  }

  // Texto
  .texto {
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    color: $cor_fonte_escura;

    b,
    strong {
      color: $cor_primaria;
      font-weight: normal;
    }

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }
  }

  // Botão
  .btn-container {
    margin-top: 0px;

    // MOBILE
    @include mq(s) {
      text-align: center !important;
    }

    .btn {
      // MOBILE
      @include mq(s) {
        font-size: 14px;
        padding: 12px 20px;
      }
    }
  }
}

// Direita (Foto)
.home-sobre-right {
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  // MOBILE
  @include mq(s) {
    width: 100%;
    margin: 0px;
    margin-bottom: 15px;
  }

  img {
    transition: 0.5s ease-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
}
