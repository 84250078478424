// Foto
.servico-detalhe-foto-container {
  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
  }
}
.servico-detalhe-foto {
}

// Texto
.servico-detalhe-texto {
  // BOTÃO
  .btn-container {
    float: none;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }
  }
}
