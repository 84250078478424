// Depoimento item
.advogado-item {
  margin: 0 10px;
  padding: 20px;
  background: $cor_fundo2;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  transition: .5s;
  height: 195px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);


  // TABLET
  @include mq(m) {
    text-align: center;
    height: 300px;
  }

  // MOBILE
  @include mq(s) {
    text-align: center;
    height: auto;
  }

  // Foto
  figure {
    float: left;
    //width: 150px;
    //height: 160px;
    margin-right: 15px;
    overflow: hidden;
    display: block;
    border-radius: 5px;
    max-width: 100%;

    img {
      transition: .5s ease-out;
    }

    &:hover img {
      transform: scale(1.1);
    }


    // TABLET
    @include mq(m) {
      float: none;
      display: inline-block;
      margin-right: 0px;
    }

    // MOBILE
    @include mq(s) {
      float: none;
      display: inline-block;
      margin-right: 0px;
    }

  }

  // Direita
  >div {
    float: left;
    width: calc(100% - 170px);

    // TABLET
    @include mq(m) {
      width: 100%;
      margin-top: 10px;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-top: 10px;
    }

    // Texto
    .advogado-item-texto {
      color: #777;
      font-size: 16px;
      margin-right: 10px;
      line-height: 1.5;

    }

    // area
    .advogado-item-area {
      display: block;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 7px;
    }

    // nome
    .advogado-item-nome {
      float: left;
      width: 100%;
      color: #333;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 7px;
      text-transform: uppercase;
    }

    // ESPECIALIDADE
    .advogado-item-especialidade {
      color: $cor_primaria;
    }

    .link {
      margin-top: 10px;
      display: block;
      font-weight: bold;
    }
  }

  // DESTAQUE
  &.destaque {
    background: #40A3B6;

    .advogado-item-area,
    .advogado-item-contato {

      color: white;
    }

    .advogado-item-contato i {

      color: #00849D;
    }


  }
}
