// Bloco serviço
.servico {
  position: relative;
  overflow: hidden;
  max-width: 500px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 20px;
  
  // Foto
  figure img {
    transform: scale(1.2);
    transition: .5s ease-out;
  }
  &:hover figure img {
    transform: scale(1);
  }
  
  // Máscara
  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .3);
    transition: .3s ease-out;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &:hover span {
    background: rgba(#000, .6);
  }
  
  // Título
  h2 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(#000, .3);
    margin-top: 40px;
    margin-bottom: 15px;
    
    // MOBILE
    @include mq(s) {
      margin-top: 0px;
      font-size: 18px;
    }
  }
  
  // Botão
  .btn {
    opacity: 0;
    transform: scale(.5);
    
    // MOBILE
    @include mq(s) {
      opacity: 1;
      transform: scale(1);
    }
  }
  &:hover .btn {
    opacity: 1;
    transform: scale(1);
  }
}

// Carrosel
.carrosel-servicos {
  margin-top: 10px;

  // Bloco serviço
  .servico {
    display: block;
    width: 100%;
    margin: 5px 10px;
  }
}

.areas-home {
  background: $cor_fundo2;
}

// Bloco serviço
.servicos-home {

  // MOBILE
  @include mq(s) {
    margin-bottom: 30px;
    height: auto;
  }

  // TABLET
  @include mq(m) {
    height: 411px;
  }

  &:hover .servico-home-titulo {
    color: $cor_primaria;
  }

}

.segura-icone{
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

}

.img-icone{
  display: inline-block;
  width: 65px;
  opacity: .5;

    // Imagem
    img {
        transition: .3s ease-out;

        // MOBILE
        @include mq(s) {
          display: inline-block;
        }
    }
    &:hover img {
        transform: scale(1.1);
    }
}

// Titulo Serviço
.servico-home-titulo {
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
  color: $cor_primaria;
  font-size: 20px;

  // MOBILE
  @include mq(s) {
    font-size: 18px;
  }
}

//Texto serviço
.servico-home-texto{
  font-size: 15px;
  color: $cor_fonte_escura;
  font-weight: 300;

    // TABLET
    @include mq(m) {
      font-size: 14px;
    }

    // MOBILE
    @include mq(s) {
     font-size: 14px;
    }
  }
