// Footer geral
.footer {
	position: relative;
	float: left;
	width: 100%;
	padding-top: 50px;
	background: #00131c;
	overflow: hidden;

	// MOBILE
	@include mq(s) {
		padding-bottom: 0px;
	}
}

// Conteúdo container
.footer-content {
	margin-bottom: 40px;
}

// Coluna
.footer-bloco {

	&:not(:last-child) {
		// MOBILE
		@include mq(s) {
			margin-bottom: 40px;
		}
	}

	// Título
	> h2 {
		font-family: $fonte_titulo2;
		color: #FFF;
		font-size: 24px;
		line-height: 1.2;
		margin-bottom: 20px;

		// MOBILE
		@include mq(s) {
			font-size: 24px;
		}
	}

	// Lista links
	.footer-lista {
		// Item
		li {
			float: left;
			width: 100%;
			margin-bottom: 15px;
			font-size: 15px;
			font-weight: 300;
			line-height: 1.2;
			color: #FFF;

			&:last-child {
				margin-bottom: 0px;
			}

			// TABLET
			@include mq(m) {
				width: 100%;
			}

			// MOBILE
			@include mq(s) {
				width: 100%;
			}
		}

		// Link
		a {
			color: #FFF;
			transition: .2s ease-out;

			&:hover {
				color: $cor_primaria;
			}
		}

		// Ícone
		i {
			margin-right: 10px;
		}
	}
}

// Mapa do site
.footer-mapa {}

// Newsletter
.footer-newsletter {
  padding-right: 40px;

  // TABLET
  @include mq(m) {
    padding-right: 20px;
  }

  // MOBILE
  @include mq(s) {
    padding-right: 0px;
  }

  // Texto
  .texto {
    font-size: 16px;
    font-weight: 300;
    color: #FFF;
    margin-bottom: 20px;
  }

  // Campo
  .campo-container {
    //float: left;
    //width: calc(100% - 120px);
    margin: 0px;
    margin-bottom: 10px;
   
    // TABLET
    @include mq(m) {
      width: 100%;
      margin-bottom: 10px;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-bottom: 10px;
    }

    // Campo
    .campo {      
      height: 35px;     
      border: none;
      font-size: 14px;
      text-align: center;

      // MOBILE
      @include mq(s) {
        font-size: 16px;
      }
      
      // DESKTOP
      @include mq(l) {
        border-radius: 0px;
      }
    }
  }   

  // Botão
  .btn {
    //float: left;
    width: 100%;
    height: 35px;
    padding: 0px;
    font-size: 13px;

    // DESKTOP
    @include mq(l) {
      border-radius: 0px;
    }

    // TABLET
    @include mq(m) {
      width: 100%;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
    }
  }
}

// Atendimento
.footer-atendimento {
	padding-left: 40px;

	// TABLET
	@include mq(m) {
		padding-left: 20px;
	}

	// MOBILE
	@include mq(s) {
		padding-left: 0px;
	}

	.footer-lista li {
		width: 100%;
	}
}

// Barra footer
.barra-footer {
	background: darken(#00131c, 2%);
	padding: 10px 0;
	font-size: 15px;
	font-weight: 300;
	line-height: 1.2;
	color: #FFF;

	[class^="grid-"]:not(.redes-sociais) {
		padding-top: 10px;
	}

	// Copyright
	.copyright {
		text-align: center;
	}

	// Desenvolvimento
	.development {
		text-align: right;

		// MOBILE
		@include mq(s) {
			text-align: center;
		}

		a {
			color: #FFF !important;
		}
	}
}

// Redes sociais
.redes-sociais {
	text-align: left;

	// MOBILE
	@include mq(s) {
		text-align: center;
	}

	// Item
	a {
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 43px;
		border-radius: 50%;
		text-align: center;
		margin: 0 5px;
		background: $cor_primaria;
		transition: .3s ease-out;

		&:hover {
			background: #FFF;
		}

		i {
			color: #FFF;
			font-size: 20px;
			transition: .3s ease-out;
		}
		&:hover i {
			color: $cor_primaria;
		}
	}
}

// Voltar ao topo
.gotop {
  position: fixed;
  z-index: $z_index_gotop;
  bottom: 20px; 
  right: 20px;
  width: 50px; 
  height: 50px;
  border-radius: 50%; 
  background: rgba($cor_primaria,.8);
  cursor: pointer;
  box-shadow: 0px 3px 5px rgba(0,0,0,0.3); 
  transform: translate(0,100px);
  opacity: 0;
  visibility: hidden;
  transition: .3s ease-out;
  
  &:hover { 
    background: rgba($cor_primaria,1);
  }
  
  &.active { 
    transform: translate(0,0);
    opacity: 1;
    visibility: visible;
  }
  
  &::before { 
    content: ''; 
    position: absolute; 
    top: 0px; 
    bottom: 0px; 
    left: 0px; 
    right: 0px; 
    margin: auto; 
    display: block; 
    width: 15px; 
    height: 15px; 
    border: 2px solid #FFF; 
    transform: rotate(45deg); 
    border-bottom: none; 
    border-right: none; 
    margin-top: 20px; 
  }
}
